import React from 'react';
import styles from './ButtonTwo.module.css';

function ButtonTwo({additionalClassNames, text, onClick, color}) {
    return ( 
        <div className={`${additionalClassNames} ${styles.buttonTwo}`} onClick={onClick} style={{backgroundColor: color}}>
            <p>{text}</p>
        </div>
    );
}

export default ButtonTwo;