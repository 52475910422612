import React from "react";

// Components
import Navbar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

// Pages
import Home from "./pages/home";
import Menu from "./pages/menu";
import GiftCardPage from "./pages/giftCardPage";
import Media from "./pages/media";
import Team from "./pages/team";
import Footer from "./components/Footer";
import CallButton from "./components/CallButton";

// Styles
import './App.css';

function App() {
  return (
    <div>
      {/* ----------------------- Fonts -------------------------- */}
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap" rel="stylesheet"></link>

      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin></link>
      <link href="https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"></link>


      <Router>
        {/* Navigation Bar */}
        <Navbar />

        {/* React Router  */}
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/gift-card" element={<GiftCardPage />} />
            <Route path="/media" element={<Media />} />
            <Route path="/team" element={<Team />} />
        </Routes>

        {/* ----- FOOTER ----- */}
        <CallButton/>

        {/* ----- FOOTER ----- */}
        <Footer/>
      </Router>
    </div>
    
  );
}

export default App;
