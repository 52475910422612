import React from 'react';
import styles from './MenuBanner.module.css';

// Components
import HeadingTwo from '../HeadingTwo';
import ParagraphOne from '../ParagraphOne';

function MenuBanner() {
    return ( 
        <div className={styles.menuBanner}>
            <HeadingTwo additionalClassNames={styles.menuTitle}>MENU</HeadingTwo>
            <ParagraphOne additionalClassNames={styles.menuDescription}>This mission is simple: serve delicious, affordable food that guests will want to return to week after week.</ParagraphOne>
        </div>
     );
}

export default MenuBanner;