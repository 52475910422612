import React from 'react';
import styles from './giftCardsPage.module.css';

// Images and videos
import ComingSoonImage from '../assets/images/coming_soon.JPG';

function GiftCardPage() {
    return ( 
        <div>
            <div className={styles.giftCardSection}>
                <img src={ComingSoonImage} className={styles.comingSoonImage} alt='Gift card'></img>
            </div>
        </div>
    );
}

export default GiftCardPage;