import React from 'react';
import styles from './ButtonOne.module.css';

function ButtonOne({additionalClassNames, text, onClick, color}) {
    return ( 
        <div className={`${additionalClassNames} ${styles.baseButton}`} onClick={onClick} style={{backgroundColor: color}}>
            <p>{text}</p>
        </div>
     );
}

export default ButtonOne;