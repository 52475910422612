import React from 'react';
import styles from './media.module.css';

// Components
import HeadingOne from '../components/HeadingOne';

// Images and Videos
import HorOne from '../assets/images/media_page_pictures/h_beef_hot_dog.JPG';
import HorTwo from '../assets/images/media_page_pictures/h_eggs.JPG';
import HorThree from '../assets/images/media_page_pictures/h_fish_chips_two.JPG';
import HorFour from '../assets/images/media_page_pictures/h_fish_chips.JPG';
import HorFive from '../assets/images/media_page_pictures/h_salad.JPG';
// import HorSix from '../assets/images/media_page_pictures/h_french_toast.jpeg';
import HorSeven from '../assets/images/media_page_pictures/h_french_toast.jpeg';

import SqrOne from '../assets/images/media_page_pictures/s_dessert.JPG';
import SqrTwo from '../assets/images/media_page_pictures/s_fried_fish.JPG';
import SqrThree from '../assets/images/media_page_pictures/s_pasta.JPG';

import VerOne from '../assets/images/media_page_pictures/rockys_burger.JPG';
// import VerTwo from '../assets/images/media_page_pictures/v_eggs.JPG';
import VerThree from '../assets/images/media_page_pictures/v_french_toast.JPG';
import VerFour from '../assets/images/media_page_pictures/v_gyro.JPG';

function Media() {
    return ( 
        <div className={styles.mediaPage}>
            <HeadingOne additionalClassNames={styles.mediaPageTitle}>SAVOR EVERY BITE</HeadingOne>
            <div className={styles.mediaContent}>
                {/* Left Column */}
                <div className={styles.mediaContentColumn}>
                    <div className={`${styles.pictureFrame} ${styles.horizontalPicture}`}>
                        <img src={HorTwo} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.verticalPicture}`}>
                        <img src={VerOne} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.squarePicture}`}>
                        <img src={SqrOne} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.horizontalPicture}`}>
                        <img src={HorOne} alt={'food'}/>
                    </div>
                </div>

                {/* Middle Column */}
                <div  className={styles.mediaContentColumn}>
                    <div className={`${styles.pictureFrame} ${styles.verticalPicture}`}>
                        <img src={VerThree} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.squarePicture}`}>
                        <img src={SqrTwo} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.horizontalPicture}`}>
                        <img src={HorThree} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.horizontalPicture}`}>
                        <img src={HorFive} alt={'food'}/>
                    </div>
                </div>

                {/* Right Column */}
                <div className={`${styles.mediaContentColumn} ${styles.rightColumn}`}>
                    <div className={`${styles.pictureFrame} ${styles.horizontalPicture}`}>
                        <img src={HorFour} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.squarePicture}`}>
                        <img src={SqrThree} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.horizontalPicture}`}>
                        <img src={HorSeven} alt={'food'}/>
                    </div>

                    <div className={`${styles.pictureFrame} ${styles.verticalPicture}`}>
                        <img src={VerFour} alt={'food'}/>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Media;