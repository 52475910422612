import React from 'react';
import styles from './CustomerReviewCard.module.css';

// Components

// Images and videos
import YellowStar from '../../assets/icons/star_yellow.png';
import BlackStar from '../../assets/icons/star_black.png';

function CustomerReviewCard({name, imgSrc, imgAlt, stars, children}) {
    // Create Star list
    const starsList = [];
    for (let i = 0; i < stars; i++) {
        starsList.push(
            <div className={styles.starImageContainer}>
                <img src={YellowStar} alt='Yellow rating star'/>
            </div>   
        );
    }

    for (let i = 0; i < 5 - stars; i++) {
        starsList.push(
            <div className={styles.starImageContainer}>
                <img src={BlackStar} alt='Black rating star'/>
            </div>   
        );
    }

    return ( 
        <div className={styles.customerReviewCard}>
            {/* Potrait */}
            <div className={styles.personImgContainer}>
                <img src={imgSrc} alt={imgAlt}/>
            </div>

            {/* Text */}
            <p>{children}</p>

            {/* Stars */}
            <div className={styles.starContainer}>
                {starsList}
            </div>

            {/* Name */}
            <p>{name}</p>
        </div>
    );
}

export default CustomerReviewCard;