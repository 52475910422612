import React from 'react';
import styles from './menu.module.css';

// Components
import MenuBanner from '../components/MenuBanner';

// Images and videos
import PageOne from '../assets/images/menu/menu_page_one.jpeg';
import PageTwo from '../assets/images/menu/menu_page_two.jpeg';

function Menu() {
    return ( 
        <div>
            {/* Banner */}
            <MenuBanner/>

            {/* Menu section */}
            <div className={styles.menuPagesContainer}>
                {/* Page One */}
                <img className={styles.menuPage} src={PageOne} alt='menu page'></img>

                {/* Page Two */}
                <img className={styles.menuPage} src={PageTwo} alt='menu page'></img>
            </div>
        </div>
     );
}

export default Menu;