import React from 'react';
import styles from './DesktopNavBar.module.css';
import { NavLink } from "react-router-dom";

// Components
import ButtonOne from '../ButtonOne';

// Images and videos
import Logo from '../../assets/images/logo.png';

function DesktopNavBar() {
    const handleOrderNowClick = () => {
        window.location.href = 'https://order.toasttab.com/online/rockys-2-1622-south-wayne-road';
    }

    // Scroll to top of the page upon page selection
    const handleClick = () => {
        window.scrollTo({
            top: 0
        });
    };

    // Scroll to top of the page upon page selection and open email client
    const handleClickContact = () => {
        window.scrollTo({
            top: 0
        });

        window.location.href = "mailto:rockysbilling@gmail.com";
    };

    return ( 
        <div className={styles.desktopNavBar}>
            {/* Logo */}
            <div className={styles.logoContainer}>
                <img className={styles.logo} src={Logo} alt='Logo'></img>
            </div>

            {/* Navigation Options */}
            <div className={styles.navOptionsContainer}>
                <div className={styles.navOptionsContainerSecondary}>
                    <NavLink to="/" className={styles.navigationOption} onClick={handleClick}>Home</NavLink>
                    <NavLink to="/menu" className={styles.navigationOption} onClick={handleClick}>Menu</NavLink>
                    {/* <NavLink to="/gift-card" className={styles.navigationOption} onClick={handleClick}>Gift Cards</NavLink> */}
                    <NavLink to="/media" className={styles.navigationOption} onClick={handleClick}>Media</NavLink>
                    <NavLink to="/team" className={styles.navigationOption} onClick={handleClick}>Join the Team</NavLink>
                    <NavLink to="/" className={styles.navigationOption} onClick={handleClickContact}>Contact Us</NavLink>
                </div>
            </div>

            {/* Order Button */}
            <div className={styles.navButtonContainer}>
                <div className={styles.navButtonContainerSecondary}>
                    <ButtonOne additionalClassNames={styles.giftCardButton} text={'ORDER NOW'} onClick={handleOrderNowClick}/>
                </div>
            </div> 
        </div>
     );
 }
 
 export default DesktopNavBar;