import React from 'react';
import styles from './CallButton.module.css';

function CallButton() {

    const handlePhoneCall = () => {
        window.location.href = 'tel:+17347270640';
    };

    return ( 
        <div className={styles.callButton} onClick={handlePhoneCall}>
            <p className={styles.callNowTitle}>CALL NOW</p>
        </div>
     );
}

export default CallButton;