import React from 'react';
import styles from './Footer.module.css';

// Components
import HeadingTwo from '../HeadingTwo';
import ParagraphOne from '../ParagraphOne';

// Images and videos
import FacebookLogo from '../../assets/icons/facebook_logo.webp';
import InstagramLogo from '../../assets/icons/instagram_logo.webp';

function Footer() {
    return ( 
        <div className={styles.footer}>
            <div className={styles.footerContentContainer}>
                {/* Map */}
                <div className={styles.mapContainer}>
                    <iframe title='restaurant location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2951.146814876233!2d-83.39102032400797!3d42.29673263910019!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b4dcf4b75462f%3A0x8c988ecc32f9fcf3!2sRocky&#39;s%20Family%20Dining!5e0!3m2!1sen!2sus!4v1715640502920!5m2!1sen!2sus" width="600" height="450" className={styles.mapStyles} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

                {/* Address and social media */}
                <div className={styles.addressAndSocialMediaContainer}>
                    <HeadingTwo additionalClassNames={styles.addressTitle}>COME VISIT US</HeadingTwo>
                    <ParagraphOne additionalClassNames={styles.addressText}>1622 S. Wayne Rd. Westland, MI 48186</ParagraphOne>

                    <div className={styles.socialMediaContainer}>
                        <div className={styles.logoContainer}>
                            <a href="https://www.facebook.com/rockyswestland" target='blank'><img src={FacebookLogo} alt='Facebook Logo'></img></a>
                        </div>
                        <div className={styles.logoContainer}>
                            <a href="https://www.instagram.com/rockys_family_dining/" target='blank'><img src={InstagramLogo} alt='Instagram Logo'></img></a>
                        </div>
                    </div>
                </div>

                {/* Opening and closing hours */}
                <div>
                    <HeadingTwo additionalClassNames={styles.addressTitle}>HOURS</HeadingTwo>
                    <ParagraphOne additionalClassNames={styles.addressText}>Monday - 6 AM-9 PM</ParagraphOne>
                    <ParagraphOne additionalClassNames={styles.addressText}>Tuesday - 6 AM-9 PM</ParagraphOne>
                    <ParagraphOne additionalClassNames={styles.addressText}>Wednesday - 6 AM-9 PM</ParagraphOne>
                    <ParagraphOne additionalClassNames={styles.addressText}>Thursday - 6 AM-9 PM</ParagraphOne>
                    <ParagraphOne additionalClassNames={styles.addressText}>Friday - 6 AM-10 PM</ParagraphOne>
                    <ParagraphOne additionalClassNames={styles.addressText}>Saturday - 6 AM-9 PM</ParagraphOne>
                    <ParagraphOne additionalClassNames={styles.addressText}>Sunday - 6 AM-8 PM</ParagraphOne>
                </div>
            </div>
            
            <ParagraphOne>Copyright © 2024 Rocky's Family Dining - All Rights Reserved.</ParagraphOne>
            <ParagraphOne>Made by <a className={styles.companyNameLink} href='https://www.instagram.com/whale_media_agency/' target='blank'>Whale Media</a> 🐋</ParagraphOne>
        </div>
    );
}

export default Footer;