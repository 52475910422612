import React from "react";
import { NavLink } from "react-router-dom";

// Navigation Bars
import DesktopNavBar from "./DesktopNavBar";
import NavBarMobile from "./NavBarMobile";
 
function Navbar() {

    // (Mobile) Collapse the navigation bar and scroll to top of the page upon page selection
    const handleClick = () => {
        var linkContainer = document.getElementById('links');
        linkContainer.style.display = "none";

        window.scrollTo({
            top: 0
        });
    };

    // (Mobile) Collapse the navigation bar and scroll to top of the page upon page selection and 
    // open email client
    const handleClickContact = () => {
        window.scrollTo({
            top: 0
        });

        window.location.href = "mailto:rockysbilling@gmail.com";
    };

    return ( 
        <div>
            {/* ----- Desktop navigation ba -----  */}
            <DesktopNavBar/>

            {/* -----  Mobile navigation bar -----  */}
            <NavBarMobile>
                <NavLink onClick={handleClick} to="/">Home</NavLink>

                <NavLink onClick={handleClick} to="/menu">Menu</NavLink>

                {/* <NavLink onClick={handleClick} to="/gift-card">Gift Cards</NavLink> */}

                <NavLink onClick={handleClick} to="/media">Media</NavLink>

                <NavLink onClick={handleClick} to="/team">Join the Team</NavLink>

                <NavLink onClick={handleClickContact} to="/">Contact Us</NavLink>
            </NavBarMobile>
        </div>
     );
}

export default Navbar;