import React, { useState } from 'react';
import styles from './team.module.css';
import emailjs from 'emailjs-com';

// Components
import HeadingTwo from '../components/HeadingTwo';
import ParagraphOne from '../components/ParagraphOne';


// Images and videos
import EmployeeImage from '../assets/images/employee_test.jpeg';
import EmployeeImageTwo from '../assets/images/employee_test_two.jpeg';


function Team() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [positionOne, setPositionOne] = useState('unchecked');
    const [positionTwo, setPositionTwo] = useState('unchecked');
    const [positionThree, setPositionThree] = useState('unchecked');

    function handleFirstNameChange(e) {
        const name = e.target.value;
        setFirstName(name);
    }

    function handleLastNameChange(e) {
        const name = e.target.value;
        setLastName(name);
    }

    function handleEmailChange(e) {
        const emailVal = e.target.value;
        setEmail(emailVal);
    }

    function handlePhoneNumberChange(e) {
        const number = e.target.value;
        setPhoneNumber(number);
    }

    function handlePositionOneChange(e) {
        const checkboxVal = e.target.value;

        if (checkboxVal === 'unchecked') {
            setPositionOne('checked');
        } else {
            setPositionOne('unchecked');
        }
    }

    function handlePositionTwoChange(e) {
        const checkboxVal = e.target.value;

        if (checkboxVal === 'unchecked') {
            setPositionTwo('checked');
        } else {
            setPositionTwo('unchecked');
        }
    }

    function handlePositionThreeChange(e) {
        const checkboxVal = e.target.value;

        if (checkboxVal === 'unchecked') {
            setPositionThree('checked');
        } else {
            setPositionThree('unchecked');
        }
    }

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        // Sends application to Rocky's email
        emailjs.sendForm('service_k7ap29p', 'template_f8q96vq', e.target, 'HGDHEeKc6gMkVm2SJ')
            .then((result) => {

                // Sends confirmation email
                emailjs.sendForm('service_k7ap29p', 'template_px7k7q9', e.target, 'HGDHEeKc6gMkVm2SJ')
                    .then((result) => {
                        alert('Application sent successfully!');
                        window.location.reload()
                    }, (error) => {
                        alert('There has been an error while sending confirmation email.');
                        console.log(error.text);
                    });
            }, (error) => {
                alert('There has been an error while sending the application. Try again.');
                console.log(error.text);
            });

    }

    return ( 
        <div className={styles.joinTheTeamPage}>
            <div className={styles.contentContainer}>
                {/* Left content (form) */}
                <div className={styles.leftContent}>
                    {/* Page intro */}
                    <div className={styles.teamIntro}>
                        <HeadingTwo>WANT TO JOIN OUR TEAM?</HeadingTwo>
                        <ParagraphOne>Fill out some info and we will be in touch shortly! We can't wait to hear from you!</ParagraphOne>

                        <div className={styles.waitressImageContainerMobile}>
                            <img className={styles.waitressImageMobile} src={EmployeeImageTwo} alt='Waiter'></img>
                        </div>
                        
                    </div>

                    {/* Form */}
                    <form className={styles.formContainer} onSubmit={sendEmail}>
                        {/* Full name section */}
                        <p>Name</p>
                        <div className={styles.nameContainer}>
                            <div className={styles.nameContainerHalf}>
                                {/* First Name  */}
                                <label htmlFor="firstName">First name</label>
                                <input className={styles.fullSizeInput} type="text" name="firstName" id="firstName" onChange={handleFirstNameChange} value={firstName} required></input>
                            </div>
                            <div className={styles.nameContainerHalf}>
                                {/* Last Name */}
                                <label htmlFor="lastName">Last name</label>
                                <input className={styles.fullSizeInput} type="text" name="lastName" id="lastName" onChange={handleLastNameChange} value={lastName} required></input>
                            </div>
                        </div>

                        {/* Email */}
                        <div className={styles.inputSection}>
                            <label htmlFor="email">Email</label>
                            <input className={styles.fullSizeInput} type="text" name="email" id="email" onChange={handleEmailChange} value={email} required></input>
                        </div>

                        {/* Phone */}
                        <div className={styles.inputSection}>
                            <label htmlFor="phone">Phone</label>
                            <input className={styles.fullSizeInput} type="tel" name="phone" id="phone" placeholder="888 888 8888" onChange={handlePhoneNumberChange} value={phoneNumber} required></input>
                        </div>

                        {/* Positions of interest */}
                        <p>Which position are you interested in?</p>
                        <div className={styles.inputSection}>
                            <input type="checkbox" id="positionOne" name="positionOne" onChange={handlePositionOneChange} value={positionOne}></input>
                            <label for="positionOne"> Sous Chef</label>

                            <br></br>

                            <input type="checkbox" id="positionTwo" name="positionTwo" onChange={handlePositionTwoChange} value={positionTwo}></input>
                            <label for="positionTwo"> Waiter</label>

                            <br></br>

                            <input type="checkbox" id="positionThree" name="positionThree" onChange={handlePositionThreeChange} value={positionThree}></input>
                            <label for="positionThree"> Host</label>
                        </div>

                        {/* How did you hear about us section */}
                        <div className={styles.inputSection}>
                            <label for="feedback">How did you hear about us?</label>
                            <select className={styles.fullSizeInput} name="feedback" id="feedback">
                                <option value="Internet Search/Google">Internet Search/Google</option>
                                <option value="Social Media (Facebook, Instagram, Twitter, etc.)">Social Media (Facebook, Instagram, Twitter, etc.)</option>
                                <option value="Friend or Family Referral">Friend or Family Referral</option>
                                <option value="Online Advertisement">Online Advertisement</option>
                                <option value="Flyer or Brochure">Flyer or Brochure</option>
                            </select>
                        </div>

                        {/* Message area */}
                        <div className={styles.inputSection}>
                            <label for="message">Message</label>
                            <textarea className={styles.messageTextArea} id="message" name="message" rows="4" cols="50"></textarea>
                        </div>

                        {/* Submit button */}
                        <input className={styles.formSubmitButton} type='submit' value="SUBMIT"/>
                    </form>
                </div>

                {/* Right content (image) */}
                <div className={styles.rightContent}>
                    <div className={styles.staffImageContainer}>
                        <img src={EmployeeImage} alt={'employee'}/>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Team;